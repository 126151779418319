<template>
  <div>
    <b-row>
      <b-col md="8">
        <h2>Time Limit Setting</h2>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <template #label>
            <span class="d-none d-md-block">Search User</span>
          </template>
          <b-form-input
            v-model="searchFor"
            class="float-right"
            placeholder="Search..."
          />
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Teams Table -->
    <b-table
      v-if="myTeams.length"
      :fields="fields"
      :items="myTeams"
      responsive
      show-empty
    >
      <!-- User Name -->
      <template #cell(name)="row">
        <div class="col-200">
          <b-avatar
            :src="getAvatar(row.item.avatar)"
            :text="getInitials(row.item.name)"
            :variant="getRandomVariant()"
            badge
            :badge-variant="row.item.isOnline ? 'success' : 'secondary'"
            class="mr-1"
          />
          {{ row.item.name }}
        </div>
      </template>

      <!-- Limit -->
      <template #cell(hours)="row">
        <div class="col-160">
          <div v-if="row.item.hours">
            {{ row.item.hours }} hrs /
            <span class="text-lowercase">{{ row.item.limitType.substring(0, row.item.limitType.length - 2) }}</span>
          </div>
          <span v-else>No Limit</span>
        </div>
      </template>
      <template #cell(actions)="row">
        <b-row>
          <b-col md="3">
            <b-button
              variant="primary"
              size="sm"
              class="py-1 mb-1"
              :disabled="!canEdit"
              @click="showModal(row.item)"
            >
              <feather-icon
                icon="Edit2Icon"
              />
            </b-button>
          </b-col>

          <b-col md="3">
            <b-button
              v-if="row.item.hours"
              variant="danger"
              size="sm"
              class="py-1"
              :disabled="!canEdit"
              @click="showModal(row.item, 'delete')"
            >
              <feather-icon
                icon="TrashIcon"
              />
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-table>

    <!-- Time Limit Setting Modal -->
    <b-modal
      id="time-limit-setting-modal"
      :title="`Edit Time Limit for ${form.name}`"
      ok-title="Submit"
      :busy="isProcessing"
      @ok="updateTimeLimit"
      @hidden="() => form = {}"
    >
      <b-overlay :show="isProcessing">
        <validation-observer ref="timeLimitSettingForm">
          <!-- Time Limit -->
          <validation-provider
            #default="{errors}"
            rules="required"
            name="time limit"
          >
            <b-form-group label="Time Limit (hrs)">
              <b-form-input
                v-model="form.hours"
                type="text"
                placeholder="Time Limit in Hours"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Limit Type -->
          <validation-provider
            #default="{errors}"
            rules="required"
            name="limit for"
          >
            <b-form-group label="Limit For">
              <b-form-select
                v-model="form.type"
                :options="limitForOptions"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </validation-observer>
      </b-overlay>
    </b-modal>

    <!-- Time Limit Delete Modal -->
    <b-modal
      id="time-limit-delete-modal"
      ok-title="Delete"
      ok-variant="danger"
      @ok="deleteTimeLimit"
    >
      Are you sure you want to remove time limit for {{ form.name }}?
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormSelect, BTable, BButton, BModal, BOverlay, BAvatar,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { checkPermission } from '@/utils/permissions'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BTable,
    BButton,
    BModal,
    BOverlay,
    BAvatar,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    teams: {
      type: [Object, Array],
      default: () => {},
    },
    teamUid: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      required,
      limitForOptions: [
        {
          value: 'WEEKLY',
          text: 'Weekly',
        },
        {
          value: 'MONTHLY',
          text: 'Monthly',
        },
      ],
      searchFor: '',
      selectedProject: this.$store.state.project.selectedProject,
      fields: [
        { label: 'Team Member', key: 'name', thClass: 'text-capitalize' },
        { label: 'Time Limit (hrs)', key: 'hours', thClass: 'text-capitalize' },
        {
          label: 'Actions', key: 'actions', thClass: 'text-capitalize', tdClass: 'w-25',
        },
      ],
      form: {},
      selectedIndex: null,
      isProcessing: false,
      canEdit: false,
    }
  },
  computed: {
    myTeams() {
      return this.teams ? this.teams.filter(team => team.name.toLowerCase().includes(this.searchFor.toLowerCase())) : []
    },
  },
  mounted() {
    checkPermission('timing.limit', permissions => {
      this.canEdit = permissions['timing.limit'] === true
    })
  },
  methods: {
    updateTimeLimit(bvModal) {
      bvModal.preventDefault()
      this.$refs.timeLimitSettingForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useApollo.team.updateTeamMemberLimit({
            userUid: this.form.uid,
            projectUid: this.selectedProject,
            hours: parseInt(this.form.hours, 10) ?? 0,
            type: this.form.type ?? 'WEEKLY',
          }).then(res => {
            this.$emit('updated')
            this.showSuccess(res.data.updateUserProjectTimeLimit.message)
          }).finally(() => {
            this.isProcessing = false
            this.$bvModal.hide('time-limit-setting-modal')
          })
        }
      })
    },
    deleteTimeLimit() {
      this.isProcessing = true
      useApollo.team.updateTeamMemberLimit({
        userUid: this.form.uid,
        projectUid: this.selectedProject,
        hours: 0,
        type: this.form.type ?? 'WEEKLY',
      }).then(res => {
        this.$emit('updated')
        this.showSuccess(res.data.updateUserProjectTimeLimit.message)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    showModal(user, action) {
      this.form = {
        uid: user.uid,
        name: user.name,
        hours: user.hours,
        type: user.limitType ?? 'WEEKLY',
      }
      this.$nextTick(() => {
        if (action === 'delete') this.$bvModal.show('time-limit-delete-modal')
        else this.$bvModal.show('time-limit-setting-modal')
      })
    },
  },
}
</script>

<style scoped>
.col-200 {
  min-width: 200px;
}
.col-160 {
  min-width: 160px;
}
</style>
